export const populateForm = (data, formState) => {
  const form = {};
  for (let key in formState) {
    form[key] = {
      ...formState[key],
      elementConfig: { ...formState[key].elementConfig },
    };
  }
  for (let key in data) {
    if (typeof data[key] === "object" && data[key] !== null) {
      const nested = data[key];
      for (let key2 in nested) {
        if (form[key2]) {
          form[key2].value = nested[key2];
        }
      }
    } else {
      if (form[key]) {
        form[key].value = data[key];
      }
    }
  }
  return form;
};
export const validateForm = (form) => {
  var valid = true;
  // valid = valid && form[k].valid
  for (let k in form) {
    if (form[k].disabled) {
      continue;
    }
    if (form[k].validatingRules.required) {
      valid = valid && form[k].valid && form[k].value.length !== 0;
    } else valid = valid && form[k].valid;
  }
  return valid;
};

export const validateField = (value, validatingRules) => {
  var isValid = true;

  if (validatingRules.required) isValid = value.length !== 0 && isValid;
  if (validatingRules.maxLength)
    isValid = value.length <= validatingRules.maxLength && isValid;
  if (validatingRules.minLength)
    isValid = value.length >= validatingRules.minLength && isValid;
  if (validatingRules.isEmail && value !== "") {
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = pattern.test(value.toLowerCase()) && isValid;
  }
  if (validatingRules.url && value !== "") {
    let pattern = new RegExp(
      "^((ftp|http|https):\\/\\/)" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    isValid = pattern.test(value.toLowerCase()) && isValid;
  }
  if (validatingRules.number && value !== "") {
    var pattern = /^\d{9}$/;
    isValid = pattern.test(value) && isValid;
  }
  if (validatingRules.strongPassword && value !== "") {
    let pattern = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    isValid = pattern.test(value) && isValid;
  }
  if (validatingRules.phoneNo && value !== "") {
    let pattern =
      /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const navigateUrl = (url) => {
  let element = document.createElement("a");
  element.target = "_blank";
  if (url.startsWith("http://") || url.startsWith("https://")) {
    element.href = url;
  } else {
    element.href = "http://" + url;
  }

  element.click();
};
