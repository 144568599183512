import React from "react";
import { makeStyles, Typography, Container, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundImage: `url(images/c5mbg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  logo: {
    width: "50%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
}));

const Welcome = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box
          width="100%"
          height="100vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box my={2}>
            <Typography color="primary" variant="h3">
              WELCOME TO
            </Typography>
          </Box>
          <img className={classes.logo} src="images/c5m-dark.png" alt="" />
        </Box>
      </Container>
    </div>
  );
};

export default Welcome;
