import React from "react";
import { Grid } from "@material-ui/core";
import "./terms.css";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  eklogo: {
    width: "400px",
    height: "150px",

    marginInline: "auto",
  },
}));

const Termsofuse = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid style={{ minHeight: "102vh" }} container>
        <div className="Term_condition">
          <div>
            <div className="term_condition_head">
              <img
                className={classes.eklogo}
                src="images/c5m-dark.png"
                alt="C5M"
              />
              <h3>TERMS OF USE</h3>
              <p>Last revised September 22, 2022</p>
            </div>
            <br />
            <div>
              <div className="term_condition_main_heading">
                <p>
                  Welcome to the website of Continent 5 Media Group, Inc.
                  (“Website”). Please read these Terms of Use and the
                  accompanying Privacy Policy (collectively, the “Agreement”)
                  thoroughly and carefully because they set forth the conditions
                  of use of the Website and are a legally binding agreement
                  between (a) you individually and any entity on whose behalf
                  you are using the Website (collectively “you” and/or “your”),
                  and (b) Continent 5 Media Group, Inc.— which owns and operates
                  the Website—its subsidiaries and its affiliates (collectively,
                  “Continent 5 Media,” “C5M,” “we,” “our,” or “us”).
                </p>
                <br />
                <p>
                  Please consult the Privacy Policy for information on how to
                  contact us if you have questions about this Agreement or if
                  you need assistance.
                </p>
                <br />
                <p>
                  <span className="privacyinfo_head_des">
                    Important Caution to Minors:
                  </span>
                  If you are under the age of 18, you must inform us and provide
                  us with verifiable consent from your parent or guardian making
                  purchases and/or participating in activities at the Website so
                  that we comply with our obligations under the applicable
                  contract and privacy laws.
                </p>
              </div>
              <br />
              <div className="term_conditon_subheadings">
                <h4>1. Acceptance of Agreement </h4>
                <br />
                <p>
                  By accessing and using the Website, you hereby expressly
                  acknowledge and agree to be bound by the terms and conditions
                  of this Agreement, including the Terms of Use and the Privacy
                  Policy, and any future amendments and additions to this
                  Agreement as published from time to time on the Website.
                </p>
                <br />
                <div className="term_conditon_subheadings_important_p">
                  <p>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Important Note:
                    </span>{" "}
                    Please read the Privacy Policy for important privacy rights
                    available under applicable laws, including “opt-in” and
                    “opt-out” rights and the right to inspect, correct, and/or
                    delete your personal data.
                  </p>
                </div>
                <br />
                <p>
                  Continent 5 Media reserves the right, at any time, to modify,
                  alter, or update the terms and conditions of this Agreement
                  without prior notice to you. Modifications shall become
                  effective immediately upon being posted on the Website. Your
                  continued use of the Website after modifications to this
                  Agreement are posted constitutes an acknowledgment and
                  acceptance of the Agreement and its modifications. Except as
                  provided in this paragraph, this Agreement may not be amended.
                </p>
                <br />
                <p>
                  This Agreement and the Privacy Policy constitute the entire
                  agreement between you and us and supersedes all prior or
                  contemporaneous agreements. The latest Agreement will be
                  posted on the Website, and you should review this Agreement
                  before using the Website.
                </p>
                <br />
                <p>
                  If you do not agree to the terms and conditions of this
                  Agreement, then you should immediately cease all usage of the
                  Website and exit the Website now.
                </p>
                <br />
                <h4>2. Contest and Competition Rules:</h4>
                <p>
                  From time to time, we offer our users the opportunity to
                  participate in contests and competitions. Each of these
                  contests and competitions is subject to a separate set of
                  rules, and the rules include a description of who is permitted
                  to participate. You must read the applicable rules before
                  entering or participating. The rules for all contests and
                  competitions can be reached by clicking on the following link:{" "}
                  <a href="https://vacrules@eksplode.com">
                    vacrules@eksplode.com
                  </a>
                </p>
                <br />
                <h4>3. Assumption of Risk and Waiver of Claims:</h4>
                <p>
                  As a condition for our permission to access, purchase and/or
                  use the products and services that are available through the
                  Website, you acknowledge and agree that you assume any and all
                  risks that may arise in any way from your decision to use the
                  products and services that are available through the Website.
                  You waive and release any and all claims that you may be
                  entitled to assert against us and/or our shareholders,
                  directors, officers, managers, employees, contractors,
                  vendors, partners, successors, and assigns relating to your
                  use of these products or services, any other aspect of the
                  Website, and/or the other products and services of C5M.
                </p>
                <br />
                <h4>4. Limited License; Permitted Uses:</h4>

                <p>
                  The Website may be used by, and our services are available to,
                  individuals who can form legally binding contracts under
                  applicable law. If you are under the legal age, you must
                  obtain and provide us with the verifiable legal consent of a
                  parent or legal guardian. You must (a) provide all equipment
                  necessary for your Internet connection, including computer
                  and/or tablet and/or mobile device and modem, and (b) provide
                  for your access to the Internet.
                </p>
                <br />
                <p>
                  Subject to the terms and conditions set forth herein, you are
                  hereby granted a limited, non-exclusive, non-transferable,
                  restricted, and revocable right and license to (a) access and
                  use the Website strictly in accordance with this Agreement;
                  (b) use the Website solely for internal, personal,
                  non-commercial purposes; and (c) print out discrete
                  information from the Website solely for internal, personal,
                  non-commercial purposes and provided that you comply with
                  terms and conditions of this Agreement, including the
                  restrictions on the use of our intellectual property and/or
                  the intellectual property of third parties. No other right,
                  title, or interest is granted in or to the Website and/or its
                  contents.
                </p>
                <br />
                <p>
                  You shall not modify the Website nor adapt, translate, reverse
                  engineer, decompile, disassemble, and/or otherwise attempt to
                  discover the source code of the Website’s software, nor take
                  any other steps to discover the confidential information
                  and/or trade secrets contained on the Website.
                </p>
                <br />
                <h4>5. Intellectual Property </h4>
                <p>
                  All content included or available through the Website,
                  including the Website design, code, organization, text,
                  graphics, design, interfaces, and the selection, sequencing,
                  and arrangements thereof (“Content”), and all trademarks,
                  copyrights, patents, database rights and other intellectual
                  property rights relating to the Website and the products and
                  services of Continent 5 Media are owned solely and exclusively
                  by C5M or by a third party that has licensed the Content to
                  us. Any use of materials on or from the Website, including the
                  copying, reproduction, modification, adaptation, distribution,
                  use, publication, replication, any form of data extraction or
                  data mining, or other commercial exploitation of any kind,
                  without the prior written express permission of an authorized
                  officer of C5M, is strictly prohibited, except as expressly
                  outlined in Section 4 (Limited License; Permitted Uses) above.
                </p>
                <br />
                <p>
                  You shall not acquire ownership rights to any content,
                  document, or other materials viewed through the Website. Our
                  posting of information or materials on the Website does not
                  constitute a waiver of any right to such information and
                  materials. Some of the content on the site is the copyrighted
                  work of third parties.
                </p>
                <br />
                <p>
                  CONTINENT 5 MEDIA and logo and EKSPLODE! are among our
                  trademarks. Other product and company names mentioned on the
                  Website may be trademarks of their respective owners and are
                  used with their permission or pursuant to Fair Use.
                </p>
                <br />

                <h4> 6. Refunds:</h4>
                <p>
                  Except as otherwise required by applicable law, no refunds
                  will be given except that the actual purchase price will be
                  refunded if a product or service purchased from C5M is not
                  delivered or is defective as delivered.
                </p>
                <br />

                <h4>7. Restrictions and Prohibitions on Use </h4>
                <br />
                <p>
                  Your license for access and use of the Website and any Content
                  made available on and/or through the Website is subject to the
                  following restrictions and prohibitions on use: (a) you may
                  not make unauthorized copies of any Content on the Website or
                  distribute any Content to anyone without permission; (b) you
                  also may not use any Content from the Website in any manner
                  that may infringe any copyright, intellectual property right,
                  proprietary right, or privacy right of us or any third
                  parties; or (c) you may not use the Website to gather
                  information for or transmit unsolicited email, telephone calls
                  or facsimile transmissions.
                </p>
                <br />
                <p>
                  You are responsible for compliance with any laws, rules, and
                  regulations regarding your use of the Website and its content.
                  You shall not use or access the Website that violates
                  applicable state, federal, or international laws, regulations,
                  or other government requirements. You further agree not to
                  transmit any material that encourages conduct that could
                  constitute a criminal offense, give rise to civil liability,
                  or otherwise violates any applicable local, state, national,
                  or international law or regulation. You agree not to upload,
                  post, email, transmit, or otherwise make available any content
                  that (a) is defamatory, libelous, indecent, obscene,
                  pornographic, sexually explicit, invasive of another’s
                  privacy, promotes violence or contains hate speech; (b)
                  discloses any sensitive information about another person,
                  including that person’s email address, postal address, phone
                  number, credit card information, or any similar information
                  that could be used to identify an individual; and/or (c) is
                  intended to “stalk,” threaten, abuse or otherwise harass
                  another person. We reserve the right to remove all such
                  content and deny access to any user who posts such content.
                </p>
                <br />
                <h4>8. Your Material </h4>
                <br />
                <p>
                  You hereby grant to C5M and our successors a worldwide,
                  irrevocable, transferrable, sub-licensable, fully-paid,
                  royaltyfree, and non-exclusive license to publish, use,
                  reproduce, transmit, display, distribute and delete any data,
                  text, files, information, images, graphics, photos, profiles,
                  audio, and video clips, sounds, musical works, works of
                  authorship, applications, links and other content or materials
                  (“Material”) that you post on our Website, except for
                  commercial purposes. You further acknowledge and agree that
                  you, and you alone, are responsible for developing and posting
                  your Material. You cannot choose who can view your Material
                  because all comments to the Website blog are publicly
                  available.
                </p>
                <br />
                <p>
                  You represent and warrant that: (a) you own the Material
                  posted by you on our Website or otherwise have the right to
                  grant the rights and licenses set forth above in this Section
                  8; (b) the posting and use of your Material on or through our
                  Website does not violate, misappropriate or infringe on the
                  rights of any third party, including, without limitation,
                  privacy rights, publicity rights, copyrights, trademark and/or
                  other intellectual property rights; (c) you agree to pay for
                  all royalties, fees, and any other monies owed because of
                  Material you post on or through our Website; and (d) you have
                  the legal right and capacity to enter into this Agreement.
                </p>
                <br />
                <h4>9. Documents </h4>
                <br />
                <p>
                  Continent 5 Media may make available through the Website
                  various documents (“Documents”). All Documents are provided on
                  a non-exclusive license basis only for your one-time personal
                  use for non-commercial purposes, without any right to
                  re-license, sublicense, distribute, assign, or transfer such
                  license. Documents are provided “as is” without any
                  representations or warranties, express or implied, of legal
                  effect, completeness, or accuracy. Copyright in the Documents
                  is reserved for us or our licensors.
                </p>
                <br />
                <h4>10. Your Representations and Warranties </h4>
                <br />
                <p>
                  You represent and warrant to C5M that: (a) you are of legal
                  agent and have the full power and authority to enter into and
                  perform your obligations under this Agreement; (b) your assent
                  to and performance of your obligations under this Agreement do
                  not constitute a breach of or conflict with any other
                  agreement or arrangement by which you are bound, or any
                  applicable laws, regulations or rules; (c) all information
                  provided by you is accurate, current and will be updated by
                  you to remain current; (d) this Agreement constitutes legal,
                  valid and binding obligations on you that is enforceable in
                  accordance with its terms and conditions; (e) you will not
                  infringe the patent, copyright, trademark, trade secret,
                  privacy, right of publicity or other intellectual property or
                  proprietary right of any third party in your use of the
                  Website; (f) you will comply with all applicable laws, rules
                  and regulations in your use of the Website, as well as the
                  agreed upon terms and conditions of this Agreement; and (g)
                  you understand and acknowledge that C5M may terminate your use
                  of the Website and this Agreement as it pertains to you at any
                  time in its sole discretion.
                </p>
                <br />
                <h4>11. Linking to the Website </h4>
                <br />
                <p>
                  You may provide links to the Website in a manner that does not
                  state or suggest an affiliation with us, provided (a) that you
                  do not remove or obscure, by framing or otherwise,
                  advertisements, the copyright notice, or other notices on the
                  Website, (b) your website does not engage in illegal or
                  inappropriate activities, and (c) you discontinue providing
                  links to our Website immediately upon request by us.
                </p>
                <br />
                <h4>12. Errors, Corrections and Changes </h4>
                <br />
                <p>
                  We do not represent or warrant that our Website will be
                  error-free, free of viruses or other harmful components, or
                  that defects will be corrected. We do not represent or warrant
                  that the information available on or through the Website will
                  be correct, accurate, timely, or otherwise reliable. We may
                  change the features, functionality, or content of the Website
                  at any time. We reserve the right in our sole discretion to
                  edit or delete any documents, information, or other content
                  appearing on the Website.
                </p>
                <br />
                <h4>13. Third-Party Content </h4>
                <br />
                <p>
                  Third-party content may appear on the Website or may be
                  accessible via links from the Website. We are not responsible
                  for and assume no liability for any mistakes, misstatements of
                  law, defamation, omissions, falsehood, obscenity, pornography,
                  or profanity in the statements, opinions, representations, or
                  any other form of third-party content on the Website. You
                  understand that the information and opinions in the
                  third-party content represent the author’s thoughts solely and
                  are neither endorsed by nor does it necessarily reflect C5M’s
                  beliefs.
                </p>
                <br />
                <h4>14. Unlawful and Wrongful Activity </h4>
                <br />
                <p>
                  We reserve the right to investigate complaints or reported
                  violations of this Agreement and to take any action we deem
                  appropriate, including but not limited to reporting any
                  suspected unlawful activity to law enforcement officials,
                  regulators, or other third parties and disclosing to the
                  extent permitted by applicable law any information necessary
                  or appropriate to such persons or entities relating to your
                  profile, email addresses, usage history, posted materials, IP
                  addresses, and traffic information.
                </p>
                <br />
                <h4>15. Indemnification </h4>
                <br />
                <p>
                  You agree to indemnify, defend and hold C5M and our
                  shareholders, directors, officers, managers, employees,
                  contractors, vendors, partners, successors, and assigns
                  (collectively, “Affiliated Parties”) harmless from any
                  liability, loss, claim demand, and expense, including
                  reasonable attorneys’ fees, related to your violation of this
                  Agreement or use of the Website, including any infringement by
                  you of any intellectual property or any other right of any
                  person or entity, and/or any waived and/or released claims,
                  and/or any risk that you have assumed under this Agreement.
                  This Agreement will be binding upon and inure to the benefit
                  of any of our licensees, successors, or assigns.
                </p>
                <br />
                <h4>16. Nontransferable. </h4>
                <br />
                <p>
                  Your right to use the Website is not transferable nor
                  assignable. Any password or right given to you to obtain
                  information or documents is not transferable nor assignable.
                </p>
                <br />
                <h4>17. Disclaimer </h4>
                <br />
                <p>
                  THE INFORMATION, CONTENT, AND DOCUMENTS AVAILABLE FROM OR
                  THROUGH THE WEBSITE ARE PROVIDED “AS-IS,” “AS AVAILABLE,” WITH
                  “ALL FAULTS,” AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE
                  DISCLAIMED (INCLUDING BUT NOT LIMITED TO ANY IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, AND NON-INFRINGEMENT). TO THE FULLEST EXTENT
                  PERMITTED BY APPLICABLE LAW, CONTINENT 5 MEDIA GROUP MAKES NO
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                  THAT THE WEBSITE OR THE USE THEREOF (A) WILL BE FREE FROM
                  DEFECTS, BUGS, INACCURACIES, ERRORS, OR OTHER LIMITATIONS; (B)
                  WILL BE UNINTERRUPTED OR SECURE; (C) WILL MEET YOUR
                  REQUIREMENTS, OR (D) WILL OPERATE IN THE CONFIGURATION WITH
                  THE OTHER SOFTWARE YOU USE. CONTINENT 5 MEDIA AND OUR
                  AFFILIATED PARTIES HAVE NO LIABILITY WHATSOEVER FOR YOUR USE
                  OF ANY INFORMATION, CONTENT, OR DOCUMENTS EXCEPT AS OTHERWISE
                  PROVIDED IN THE PRIVACY POLICY. IN PARTICULAR, BUT NOT AS A
                  LIMITATION THEREOF, WE AND OUR AFFILIATED PARTIES ARE NOT
                  LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                  DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF
                  PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF
                  CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE),
                  PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES.
                </p>
                <br />
                <p>
                  THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE
                  FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU
                  AND US. THIS WEBSITE AND THE INFORMATION, CONTENT, AND
                  DOCUMENTS PRESENTED WOULD NOT BE PROVIDED WITHOUT SUCH
                  LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED BY YOU FROM US THROUGH THE WEBSITE OR
                  OTHERWISE SHALL CREATE ANY WARRANTY, REPRESENTATION, OR
                  GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT.
                </p>
                <br />
                <p>
                  ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES CAUSED BY
                  VIRUSES CONTAINED WITHIN THE ELECTRONIC FILE CONTAINING A FORM
                  OR DOCUMENT IS DISCLAIMED.
                </p>
                <br />
                <h4> 18. Limitation of Liability </h4>
                <br />
                <p>
                  In addition to the Assumption of Risk and the Waiver and
                  Release set forth above, C5M and any affiliated Party shall
                  not be liable for any loss, injury, claim, liability, or
                  damage of any kind resulting in any way from (a) any errors in
                  or omissions from the Website or any products obtainable
                  therefrom; (b) the unavailability or interruption of the
                  Website or any features thereof; (c) your use of the Website;
                  (d) Content contained on the Website; or (e) any delay or
                  failure in performance beyond the control of an affiliated
                  party.
                </p>
                <br />
                <p>
                  IN NO EVENT SHALL THE AGGREGATE LIABILITY OF C5M AND
                  AFFILIATED PARTIES ARISING FROM, RELATING TO, OR IN CONNECTION
                  WITH THIS AGREEMENT, THE WEBSITE, ANY RELATED INFORMATION,
                  CONTENT, AND/OR DOCUMENTS EXCEED ONE HUNDRED AND 00/100
                  DOLLARS ($100.00), AND THAT AMOUNT SHALL BE IN LIEU OF ALL
                  OTHER REMEDIES WHICH YOU MAY HAVE AGAINST US AND ANY
                  AFFILIATED PARTY.
                </p>
                <br />
                <p>
                  SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR
                  EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
                  DAMAGES; THEREFORE, SOME OF THE ABOVE LIMITATIONS MAY NOT
                  APPLY TO YOU.
                </p>
                <br />
                <h4>19. Use of Information </h4>
                <br />
                <p>
                  We reserve the right to collect, use, and disclose all
                  information regarding Website uses by you and all information
                  provided by you in any manner consistent with our Privacy
                  Policy, which may give you the right to opt-in, opt-out, and
                  otherwise control the use of your personal data under
                  applicable law. Please review the Privacy Policy for
                  disclosures about your privacy rights. All remarks,
                  suggestions, ideas, graphics, or other information
                  communicated by you to us will forever be our property to the
                  extent permitted under this Agreement and applicable law.
                </p>
                <br />
                <h4>20. Modifications and Interruption to Website </h4>
                <br />
                <p>
                  Continent 5 Media reserves the right to stop providing the
                  Website with or without notice to you. We shall not be liable
                  to you or any third party should we exercise our right to stop
                  providing the Website. You acknowledge and accept that (a) C5M
                  does not guarantee continuous, uninterrupted, or secure access
                  or use of the Website, and (b) operation of the Website may be
                  interfered with or adversely affected by numerous factors or
                  circumstances outside of C5M’s control.
                </p>
                <br />
                <h4>21. Third-Party Services </h4>
                <br />
                <p>
                  Continent 5 Media may allow access to or advertise certain
                  third-party products or service providers (“Merchants”) from
                  which you may purchase certain products or services. You
                  understand that we do not operate or control the products or
                  services offered by Merchants. Merchants are responsible for
                  all aspects of order processing, fulfillment, billing, and
                  customer service. We are not a party to the transactions
                  between you and the Merchants. You agree that use of or
                  purchase from such Merchants is AT YOUR SOLE RISK AND IS
                  WITHOUT WARRANTIES OF ANY KIND BY US, EXPRESSED, IMPLIED OR
                  OTHERWISE INCLUDING WARRANTIES OF TITLE, FITNESS FOR PURPOSE,
                  MERCHANTABILITY OR NON-INFRINGEMENT. UNDER NO CIRCUMSTANCES
                  ARE WE LIABLE FOR ANY DAMAGES ARISING FROM THE TRANSACTIONS
                  BETWEEN YOU AND MERCHANTS OR FOR ANY INFORMATION APPEARING ON
                  MERCHANT WEBSITES OR ANY OTHER WEBSITE LINKED TO OUR WEBSITE.
                </p>
                <br />
                <h4>22. Links to Other Websites </h4>
                <br />
                <p>
                  The Website may contain links to other websites owned and
                  operated by online merchants and other third parties and may
                  rely on third-party information to provide services through
                  the Website. You acknowledge that (a) we are not responsible
                  for the availability of, or the content located on or through,
                  any third-party site, nor the accuracy of the information
                  provided by any third party, and (b) we do not investigate,
                  monitor, or check for accuracy or completeness such websites.
                  Inclusion of any linked website on our Website does not imply
                  approval or endorsement of the linked website by us.
                </p>
                <br />
                <p>
                  If you decide to leave our Website and access these
                  third-party websites, you do so at your own risk. You should
                  contact the site administrator or webmaster for those
                  third-party sites or the administrator of any such third party
                  if you have any concerns regarding such links or the content
                  on such sites. Your use of those third-party sites is subject
                  to the terms and conditions of use and privacy policies of
                  each site, and C5M is not responsible therein. You are
                  strongly encouraged to review said terms and conditions of use
                  and third-party sites’ privacy policies.
                </p>
                <br />
                <h4>23. Legal Compliance </h4>
                <br />
                <p>
                  You agree to comply with all applicable domestic and
                  international laws, statutes, ordinances, and regulations
                  regarding your use of the Website and the Content provided
                  therein.
                </p>
                <br />
                <h4>24. DMCA Take-Down Notices. </h4>
                <br />
                <p>
                  Continent 5 Media will receive and evaluate notices of
                  copyright infringement and take appropriate actions under the
                  Digital Millennium Copyright Act, Title 17, United States
                  Code, Section 512(c)(2) (“DMCA”). Written notices of claimed
                  copyright infringement must comply with all DMCA terms and
                  conditions. They must be submitted according to the procedures
                  outlined in the DMCA to the following Designated Agent for
                  C5M:
                </p>
                <br />
                <div className="important_info">
                  <p>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Name of Agent Designated to Receive Notification of
                        Claimed Infringement on behalf of Continent 5 Media:
                      </span>
                      <span> Jonathan Kirsch, Esq.</span>
                    </p>

                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Full Address of Designated Agent to Which Notification
                        to Continent 5 Media Should Be Sent:
                      </span>{" "}
                      Law Offices of Jonathan Kirsch, 1880 Century Park East,
                      Suite 515, Los Angeles, CA 90067.
                    </p>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Telephone Number of Designated Agent:
                      </span>
                      <span> (310) 785-1200</span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Facsimile Number of Designated Agent:
                      </span>
                      <span> (310) 286-9573  </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                        }}
                      >
                        Email Address of Designated Agent:
                      </span>
                      <span> jk@jonathankirsch.com </span>
                    </p>
                  </p>
                  <br />
                  <h4>25. Miscellaneous</h4>
                  <br />
                  <p>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Governing Law and Jurisdiction.
                    </span>
                     This Agreement shall be treated as though it were executed
                    and performed in Las Vegas, Nevada, and shall be governed by
                    and construed in accordance with the laws of the State of
                    Nevada without giving effect to conflicts of law principles.
                    You agree that the venue for any action at law or in equity
                    arising out of or relating to this Agreement shall be
                    exclusively limited to the state or federal courts located
                    in Clark County, State of Nevada. You hereby consent and
                    submit to the exercise of personal jurisdiction over you by
                    such courts to litigate any such action and waive any
                    defenses of lack of minimum contacts or forum
                    non-convenient.
                  </p>
                  <br />
                  <p>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      One-Year Limitation.
                    </span>
                    Any cause of action by you concerning the Website (and/or
                    any information, Content, Documents, products, or services
                    related thereto) must be instituted within one (1) year
                    after the cause of action arose or be forever waived and
                    barred, to the extent permitted by law. All actions shall be
                    subject to the limitations and restrictions outlined in this
                    Agreement.
                  </p>
                  <br />
                  <p>
                    <span
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      Partial Invalidity.
                    </span>
                    If any provision of this Agreement shall be unlawful, void,
                    or unenforceable for any reason, the other provisions (and
                    any partially-enforceable provision) shall not be affected
                    thereby and shall remain valid and enforceable to the
                    maximum possible extent. You agree that this Agreement and
                    any other agreements referenced herein may be assigned by
                    C5M, in its sole discretion, to a third party in the event
                    of a merger or acquisition, or sale of all or substantially
                    all of the assets of C5M. This Agreement shall apply in
                    addition to, and shall not be superseded by, any other
                    written agreement between you and C5M.
                  </p>
                  <br />
                  <p>
                    To the extent that anything in or associated with the
                    Website is in conflict or inconsistent with this Agreement,
                    this Agreement shall take precedence. Our failure to enforce
                    any provision of this Agreement shall not be deemed a waiver
                    of Commented [JK1]: Please confirm that you are designating
                    my firm to act as your agent for receiving DMCA take-down
                    notices. If so, we need to register as your agent in the
                    U.S. Copyright Office. such provision nor of the right to
                    enforce such provision. Our rights under this Agreement
                    shall survive any termination of this Agreement.
                  </p>
                  <br />
                </div>
                <div className="term_condtion_enddata">
                  <h4>End of Terms of Use </h4>

                  <h4> Last revised July 28, 2021 </h4>
                </div>
                <div className="copyright">
                  <h4>
                    Copyright © 2022 by Continent 5 Media Group, Inc. All rights
                    reserved.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Termsofuse;
