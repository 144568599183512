import React from "react";
import { MinimalLayout } from "./layouts";
import { WithoutLayout } from "./Withoutlayout";
import { BrowserRouter, Switch } from "react-router-dom";
import { RouteWithLayout } from "./components";
import Login from "./views/Auth/Login";

import { Home } from "./views";
import SignUp from "./views/Auth/Signup";
import ForgotPassword from "./views/Auth/ForgotPassword";
// import Dashboard from "./views/DashBoard/DashBoard";
import SignUpAuthor from "./views/Auth/SignupAuthor";
import CounterComingSoon from "./views/Home/components/ComingSoon/CounterStrike";
import Aboutus from "./views/Home/components/About/Aboutus";
import PrivacyPolicy from "./views/Home/components/PrivacyPolicy/PrivacyPolicy";
import Termsofuse from "./views/Home/components/Terms/terms";
import GalaxyComingSoon from "./views/Home/components/ComingSoon/Glaxay";
import GreengenieComingsoon from "./views/Home/components/ComingSoon/Greengenie";

const Routes = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <RouteWithLayout
            path="/"
            exact
            component={Home}
            layout={MinimalLayout}
          />
          <RouteWithLayout
            path="/login"
            exact
            component={Login}
            layout={MinimalLayout}
          />
          <RouteWithLayout
            path="/sign-up/author"
            exact
            component={SignUpAuthor}
            layout={MinimalLayout}
          />
          <RouteWithLayout
            path="/sign-up/brand"
            exact
            component={SignUp}
            layout={MinimalLayout}
          />
          <RouteWithLayout
            path="/forget-password"
            exact
            component={ForgotPassword}
            layout={MinimalLayout}
          />
          <RouteWithLayout
            path="/counterstrike/comingsoon"
            exact
            component={CounterComingSoon}
            layout={WithoutLayout}
          />
          <RouteWithLayout
            path="/aboutus"
            exact
            component={Aboutus}
            layout={WithoutLayout}
          />
          <RouteWithLayout
            path="/privacypolicy"
            exact
            component={PrivacyPolicy}
            layout={WithoutLayout}
          />
          <RouteWithLayout
            path="/termsofuse"
            exact
            component={Termsofuse}
            layout={WithoutLayout}
          />
          <RouteWithLayout
            path="/galaxiemusic/comingsoon"
            exact
            component={GalaxyComingSoon}
            layout={WithoutLayout}
          />
          <RouteWithLayout
            path="/greengenie/comingsoon"
            exact
            component={GreengenieComingsoon}
            layout={WithoutLayout}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
