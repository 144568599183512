export const loginFormConfig = {
  email: {
    label: "Email:",
    elementType: "input",
    elementConfig: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required and must be a valid email!",
    validatingRules: {
      required: true,
      isEmail: true,
    },
  },

  password: {
    label: "Password:",
    elementType: "input",
    elementConfig: {
      name: "password",
      type: "password",
      placeholder: "Password",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required and minimum length must be 8!",
    validatingRules: {
      required: true,
      minLength: 8,
    },
  },
};
export const forgetPasswordFormConfig = {
  email: {
    label: "Email:",
    elementType: "input",
    elementConfig: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required and must be a valid email!",
    validatingRules: {
      required: true,
      isEmail: true,
    },
  },
};

export const signUpFormConfig = {
  first_name: {
    label: "First Name",
    elementType: "input",
    elementConfig: {
      name: "first_name",
      type: "text",
      placeholder: "First Name",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },
  last_name: {
    label: "Last Name",
    elementType: "input",
    elementConfig: {
      name: "last_name",
      type: "text",
      placeholder: "Last Name",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },
  As: {
    label: "As",
    elementType: "select",
    elementConfig: {
      name: "role",
      placeholder: "As",
    },
    options: {
      Brand: "brand",
      Retailer: "retailer",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },

  email: {
    label: "Email",
    elementType: "input",
    elementConfig: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required and must be a valid email!",
    validatingRules: {
      required: true,
      isEmail: true,
    },
  },
  phone: {
    label: "Phone",
    elementType: "input",
    elementConfig: {
      name: "phone",
      type: "number",
      placeholder: "Phone Number",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  company_name: {
    label: "Company Name",
    elementType: "input",
    elementConfig: {
      name: "company_name",
      type: "text",
      placeholder: "Company Name",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  name: {
    label: "Street Address",
    elementType: "input",
    elementConfig: {
      name: "name",
      type: "text",
      placeholder: "Street Address",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  country: {
    label: "Country",
    elementType: "input",
    elementConfig: {
      name: "country",
      type: "text",
      placeholder: "Country",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  city: {
    label: "City",
    elementType: "input",
    elementConfig: {
      name: "city",
      type: "text",
      placeholder: "City",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  state: {
    label: "State",
    elementType: "input",
    elementConfig: {
      name: "state",
      type: "text",
      placeholder: "State",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  zip: {
    label: "Zip",
    elementType: "input",
    elementConfig: {
      name: "zip",
      type: "text",
      placeholder: "Zip",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  website: {
    label: "Website",
    elementType: "input",
    elementConfig: {
      name: "state",
      type: "text",
      placeholder: "Company's Website",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
      url: true,
    },
  },
  category: {
    label: "Category",
    elementType: "input",
    elementConfig: {
      name: "category",
      type: "text",
      placeholder: "For example; Books, Music, Electronics etc.",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required.",
    validatingRules: {
      required: true,
    },
  },
  password: {
    label: "Password:",
    elementType: "input",
    elementConfig: {
      name: "password",
      type: "password",
      placeholder: "Password",
    },
    value: "",
    valid: true,
    validityMessage:
      "This value is required and must contain 8 characters with 1 lowercase and 1 uppercase character and 1 number and 1 symbol!",
    validatingRules: {
      required: true,
      strongPassword: true,
    },
  },
  confirmPassword: {
    label: "Confirm Password:",
    elementType: "input",
    elementConfig: {
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
    },
    value: "",
    valid: true,
    validityMessage:
      "This value is required and must contain 8 characters with 1 lowercase and 1 uppercase character and 1 number and 1 symbol!. It must be same are password.",
    validatingRules: {
      required: true,
      strongPassword: true,
    },
  },
};

export const signUpFormConfigAuthor = {
  name: {
    label: "Name",
    elementType: "input",
    elementConfig: {
      name: "name",
      type: "text",
      placeholder: "First Name",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },
  email: {
    label: "Email",
    elementType: "input",
    elementConfig: {
      name: "email",
      type: "email",
      placeholder: "Email Address",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required and must be a valid email!",
    validatingRules: {
      required: true,
      isEmail: true,
    },
  },

  contact: {
    label: "Contact",
    elementType: "input",
    elementConfig: {
      name: "contact",
      type: "text",
      placeholder: "Contact",
    },
    value: "",
    valid: true,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },

  password: {
    label: "Password",
    elementType: "input",
    elementConfig: {
      name: "password",
      type: "password",
      placeholder: "Password",
    },
    value: "",
    valid: true,
    validityMessage:
      "This value is required and must contain 8 characters with 1 lowercase and 1 uppercase character and 1 number and 1 symbol!",
    validatingRules: {
      required: true,
      strongPassword: true,
    },
  },

  confirmPassword: {
    label: "Confirm Password",
    elementType: "input",
    elementConfig: {
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
    },
    value: "",
    valid: true,
    validityMessage:
      "This value is required and must contain 8 characters with 1 lowercase and 1 uppercase character and 1 number and 1 symbol!. It must be same are password.",
    validatingRules: {
      required: true,
      strongPassword: true,
    },
  },

  biography: {
    label: "Biography",
    elementType: "textarea",
    elementConfig: {
      name: "biography",
      type: "textarea",
      placeholder: "Biography as a Writer.",
    },
    value: "",
    valid: true,
    minRows: 5,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },
  teaser: {
    label: "Teaser",
    elementType: "textarea",
    elementConfig: {
      name: "teaser",
      type: "textarea",
      placeholder: "Teaser of the book you want to publish.",
    },
    value: "",
    valid: true,
    minRows: 5,
    validityMessage: "This value is required!",
    validatingRules: {
      required: true,
    },
  },
};
