import React, { Fragment } from "react";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core";
import Routes from "./Routes";
const App = (props) => {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Fragment>
  );
};
export default App;
