import React from "react";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Call as CallIcon, MailOutline as MailIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: "white",
    borderRadius: "48px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  contactSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  icon: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      height: "64px",
      width: "64px",
    },
  },
}));

const Contact = (props) => {
  const classes = useStyles();
  return (
    <div id="contact" className={classes.root}>
      <div className={classes.contactSection}>
        <Container maxWidth="md">
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <a href="mailto:admin@continent5media.com" target="_blank">
                  <Box mb={2}>
                    <IconButton
                      className={classes.icon}
                      color="primary"
                      variant="outlined"
                    >
                      <MailIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </a>

                <Box mb={2} fontWeight={500}>
                  <Typography variant="h4" color="primary">
                    Write To Us
                  </Typography>
                </Box>
                <a href="mailto:admin@continent5media.com" target="_blank">
                  <Box align="center">
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ textAlign: "center", wordWrap: "break-word" }}
                    >
                      admin@continent5media.com
                    </Typography>
                  </Box>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
