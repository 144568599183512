import React from "react";
import { makeStyles, Typography, Container, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "100%",
		height: "100vh",
		// backgroundImage: `url(/images/movie-bg.png)`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "70% 70%",
		background:"#ffc1cc"
	},
	logo: {
		width: "50%",
		height: "auto",
		objectFit: "cover",
		[theme.breakpoints.down("xs")]: {
			width: "70%",
		},
    borderRadius: "25px",
	},
}));

const GreengenieComingsoon = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container maxWidth="md">
				<Box
					width="100%"
					height="100vh"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center">
					<Box my={2}>
						<Typography  variant="h3">
							Coming Soon
						</Typography>
					</Box>
					<img className={classes.logo} src="/images/greengenie.png" alt="" />
				</Box>
			</Container>
		</div>
	);
};

export default GreengenieComingsoon;
