import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Alert from "../../components/Alert/Alert";
import Formfield from "../../components/Formfeild/Formfeild";
// import { authActions } from '../../redux-store'

import { signUpFormConfig, signUpFormConfigAuthor } from "./AuthFormConfig";
import { navigateUrl, validateField, validateForm } from "../../utils";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { backendD2P } from "../../api";
// import { useDispatch, useSelector } from "react-redux";
// import { authActions } from "../../redux-store";

const useStyle = makeStyles((theme) => ({
  signUpSec: {
    padding: "70px 0",
    width: "100%",
    height: "100vh",
    background: `linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)), url('/images/bgWelcome.png') 100% 0%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  Cardroot: {
    marginTop: "20px",
    maxWidth: 740,
    maxHeight: 1000,
    textAlign: "center",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 740,
      maxHeight: 1000,
    },
  },
  form: {
    padding: "10px",
    margin: "0",
  },
  btns: {
    display: "flex",
    flexFlow: "row wrap",
    "& button": {
      margin: "8px 0",
      borderRadius: "0",
      padding: "0 18px",
    },
  },
}));

const SignUpAuthor = ({ history }) => {
  const classes = useStyle();
  const [formData, setFormData] = useState(signUpFormConfigAuthor);
  const [loading, setLoading] = useState(false);
  const [registerErrors, setRegisterErrors] = useState(null);
  const formValueChangeHandler = (event, fieldIdentifier) => {
    const updatedForm = {
      ...formData,
      [fieldIdentifier]: {
        ...formData[fieldIdentifier],
        elementConfig: { ...formData[fieldIdentifier].elementConfig },
        value: event.target.value,
        valid: validateField(
          event.target.value,
          formData[fieldIdentifier].validatingRules
        ),
      },
    };
    setFormData(updatedForm);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (formData.password.value !== formData.confirmPassword.value) {
      const updatedForm = {
        ...formData,
        confirmPassword: {
          ...formData.confirmPassword,
          elementConfig: { ...formData.confirmPassword.elementConfig },
          valid: false,
        },
      };
      setFormData(updatedForm);
      return;
    }
    const postData = {
      name: formData.name.value,
      email: formData.email.value,
      phone_number: formData.contact.value,
      password: formData.password.value,
      role: "author",
      author_biography: formData.biography.value,
      book_proposal: {
        teaser: formData.teaser.value,
      },
    };
    try {
      setLoading(true);
      const {
        data: { data },
      } = await backendD2P.post(`/author/create`, postData);
      if (data) navigateUrl(`${process.env.REACT_APP_D2P_URL}/login`);
      history.push("/");
    } catch (err) {
      toast.error(err.response ? err.response.data.message : err.message);
      setRegisterErrors(err.response ? err.response.data.message : err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup - C5M</title>
      </Helmet>
      <section className={classes.signUpSec}>
        <Card className={classes.Cardroot}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Sign Up To C5M
            </Typography>
            <br />
            <Typography variant="body2" component="p">
              Already Signed Up? <Link to="/login">Login!</Link>
            </Typography>
            <hr />
            {loading && <CircularProgress />}
            {registerErrors && <Alert variant="error">{registerErrors}</Alert>}
            <form className={classes.form}>
              <Grid container lg={12} spacing={2}>
                {Object.keys(formData).map((el) => (
                  <Grid item lg={6} xs={6}>
                    <Formfield
                      key={el}
                      label={formData[el].label}
                      elementType={formData[el].elementType}
                      value={formData[el].value}
                      elementConfig={formData[el].elementConfig}
                      options={formData[el].options}
                      required={formData[el].validatingRules.required}
                      invalid={!formData[el].valid}
                      invalidMessage={formData[el].validityMessage}
                      valueChanged={(event) =>
                        formValueChangeHandler(event, el)
                      }
                    />
                  </Grid>
                ))}

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  disabled={!validateForm(formData)}
                  onClick={(e) => formSubmitHandler(e)}
                >
                  Sign Up
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default SignUpAuthor;
