/*  eslint-disable */
import React, { useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: theme.palette.transparent,
  },
  scrolledAppbar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 3px 16px ${theme.palette.primary.dark}`,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  navbtn: {
    minWidth: "120px",
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, window, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();

  const scrollTo = useCallback((id) => {
    const el = document.querySelector(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return (
    <AppBar
      {...rest}
      className={clsx({
        [classes.root]: true,
        [className]: true,
        [classes.scrolledAppbar]: trigger,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="md">
          <Box display="flex" justifyContent="space-between">
            <Button
              className={classes.navbtn}
              color="primary"
              variant="contained"
              onClick={async () => {
                await history.push("/");
                scrollTo("#about");
              }}
            >
              About Us
            </Button>
            {/* <Button
              className={classes.navbtn}
              color="primary"
              variant="contained"
              onClick={() => history.push("/login")}
            >
              Sign up
            </Button> */}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
