import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import Contact from "../Contact/Contact";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(images/bg.png)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },

  products: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    padding: "20px",
    alignItems: "center",
    background: "#FFFFFF",
    width: "100%",
    borderShadow: "28px 28px 50px rgba(13, 39, 80, 0.16)",
    borderRadius: "24px",
    height: "350px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      height: "270px",
      width: "100%",
    },
  },
  logo: {
    marginTop: "30px",
    width: "45%",
    // height: "auto",
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  logo1: {
    marginTop: "-60px",
    width: "50%",
    height: "auto",
    objectFit: "contain",

    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "-20px",
      width: "60%",
    },
  },
  logo2: {
    marginTop: "-35px",
    width: "45%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      marginTop: "-55px",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginTop: "-25px",
    },
  },
  logo3: {
    marginTop: "-2%",
    width: "45%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      marginTop: "-15px",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  logo4: {
    marginTop: "60px",
    width: "45%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  logo5: {
    marginTop: "-6%",
    width: "45%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      marginTop: "-20px",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  logo6: {
    marginTop: "-5px",
    width: "50%",
    marginRight: "5rem",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      marginLeft: "0px",
      marginRight: "0rem",
    },
    marginLeft: "65px",
  },
  greengenieLogo: {
    marginTop: "5%",
    width: "50%",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },

  eMoneyLogo: {
    // marginTop: "5%",
    width: "50%",
    marginLeft: "3rem",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      marginLeft: "0rem",
    },
  },
  lawWizLogo: {
    marginTop: "5%",
    width: "50%",
    marginLeft: "5rem",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      marginLeft: "0rem",
    },
  },
  eMoney: {
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // textAlign: "center",
  },
  d2p: {
    backgroundColor: "#FFFFFF",
    color: "#FB9C11",
  },
  greengenie: {
    backgroundColor: "#FFFFFF",
  },
  galaxy: {
    // backgroundImage: `url(images/galaxy-background.png)`,
    backgroundColor: "#FFFFFF",
  },
  movie: {
    // backgroundImage: `url(images/movie-bg.png)`,
    // backgroundColor: theme.palette.white,
  },
  eksplode: {
    // backgroundImage: `url(images/eksplode-background.jpeg)`,
  },
  IR: {
    backgroundColor: "#FFFFFF",
  },
  gaming: {
    backgroundColor: "#FFFFFF",
  },
  streamingService: {
    // backgroundColor: "#f4c2c2",
    backgroundColor: "#FFFFFF",
  },
  aroura: {
    backgroundColor: "#FFFFFF",
  },
  textColor: {
    color: "#FFD700",
  },
  irBg: {
    backgroundColor: "#FFFFFF",
  },
  // setPadding: {
  //   paddingLeft: "1rem"
  // },
  titleCenter: {
    textAlign: "center",
  },
}));

const Products = (props) => {
  const classes = useStyles();

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    console.log(width);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  });

  return (
    <div className={classes.root}>
      <Box sx={{ padding: "4rem" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a
              target="_blank"
              href="http://d2p.web.s3-website-us-east-1.amazonaws.com/"
            >
              <div
                className={clsx([
                  classes.products,
                  classes.d2p,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">STREAMING SERVICE</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Typography variant="h4" style={{ fontWeight: "600" }}>
                      PUBLISHING
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Decade 2 Publishing
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img className={classes.logo} src="images/d2plogo.png" />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a target="_blank" href="/galaxiemusic/comingsoon">
              <div
                className={clsx([
                  classes.products,
                  classes.streamingService,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">STREAMING SERVICE</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h4"
                      style={{ color: "#9433E3", fontWeight: "600" }}
                    >
                      STREAMING SERVICE
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Galaxie Music
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img className={classes.logo2} src="images/galaxie.png" />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a
              target="blank"
              href="http://titan.gaming.s3-website-us-east-1.amazonaws.com/"
            >
              <div
                className={clsx([
                  classes.products,
                  classes.gaming,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">MOBILE GAMING</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h4"
                      style={{ color: "#F00032", fontWeight: "600" }}
                    >
                      MOBILE GAMING
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Titan Mobile Gaming
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img className={classes.logo5} src="images/titanlogo.png" />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a
              target="blank"
              href="http://galaxie.ir.s3-website-us-east-1.amazonaws.com/"
            >
              <div
                className={clsx([
                  classes.products,
                  classes.ir,
                  classes.irBg,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">MUSIC</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Typography
                      variant="h4"
                      style={{ color: "#F44CB9", fontWeight: "600" }}
                    >
                      MUSIC
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Impact Records
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img className={classes.logo3} src="images/ir.png" />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a
              target="blank"
              href="http://gallery.aurora.web.s3-website-us-east-1.amazonaws.com/"
            >
              <div
                className={clsx([
                  classes.products,
                  classes.aroura,
                  classes.textColor,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">DIGITAL ART</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h4"
                      style={{ color: "#B133BE", fontWeight: "600" }}
                    >
                      DIGITAL ART
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Gallery Aurora
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img
                      className={classes.logo1}
                      src="images/gallerylgo.png"
                    />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a
              target="blank"
              href="http://eksplode.web.s3-website-us-east-1.amazonaws.com/"
            >
              <div
                className={clsx([
                  classes.products,
                  classes.eksplode,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4" >INTERACTIVITY</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Typography
                      variant="h4"
                      style={{ color: "#ef6e78", fontWeight: "600" }}
                    >
                      INTERACTIVITY
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      eksplode!
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img className={classes.logo4} src="images/eksplode.png" />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a target="blank" href="/counterstrike/comingsoon">
              <div
                className={clsx([
                  classes.products,
                  classes.movie,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">VIDEO PRODUCTION</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h4"
                      style={{ color: "#1562BD", fontWeight: "600" }}
                    >
                      VIDEO PRODUCTION
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      Counterstrike Entertainment
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img
                      className={classes.logo6}
                      src="images/counterstrike.png"
                    />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <a target="blank" href="/greengenie/comingsoon">
              <div
                className={clsx([
                  classes.products,
                  classes.greengenie,
                  classes.titleCenter,
                ])}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Box>
                      {/* <Typography variant="h4">MUSIC</Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      variant="h4"
                      style={{ color: "#61dcb7", fontWeight: "600" }}
                    >
                      EXCHANGE SERVICE
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{ fontStyle: "italic", color: "black" }}
                    >
                      GreenGenie
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <br />
                <Grid item xs={12} lg={12}>
                  <div className={classes.container}>
                    <img
                      className={classes.greengenieLogo}
                      src="images/greengenie.png"
                    />
                  </div>
                </Grid>
              </div>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <a target="blank" href="http://galaxie-records-gr.s3-website.ap-south-1.amazonaws.com/"> */}
            <div
              className={clsx([
                classes.products,
                classes.eMoney,
                classes.titleCenter,
              ])}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                  <Box>{/* <Typography variant="h4">MUSIC</Typography> */}</Box>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography
                    variant="h4"
                    style={{ color: "#fcd739", fontWeight: "600" }}
                  >
                    PAYMENT APP
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{ fontStyle: "italic", color: "black" }}
                  >
                    eMoney
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <br />
              <Grid item xs={12} lg={12}>
                <div className={classes.container}>
                  <img
                    className={classes.eMoneyLogo}
                    src="images/eMoneyGold.png"
                  />
                </div>
              </Grid>
            </div>

            {/* </a> */}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/* <a target="blank" href="/greengenie/comingsoon"> */}
            <div
              className={clsx([
                classes.products,
                classes.eMoney,
                classes.titleCenter,
              ])}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} lg={4}>
                  <Box>{/* <Typography variant="h4">MUSIC</Typography> */}</Box>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Typography
                    variant="h4"
                    style={{ color: "#1562BD", fontWeight: "600" }}
                  >
                    BLOG
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{ fontStyle: "italic", color: "black" }}
                  >
                    LawWiz
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <br />
              <Grid item xs={12} lg={12}>
                <div className={classes.container}>
                  <img className={classes.lawWizLogo} src="images/lawwiz.png" />
                </div>
              </Grid>
            </div>
            {/* </a> */}
          </Grid>
        </Grid>
        <div style={{ marginTop: "4rem" }}>
          <Contact />
        </div>
      </Box>
    </div>
  );
};

export default Products;
