import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Alert from "../../components/Alert/Alert";
import Formfield from "../../components/Formfeild/Formfeild";
// import { authActions } from '../../redux-store'

import { signUpFormConfig, signUpFormConfigAuthor } from "./AuthFormConfig";
import { navigateUrl, validateField, validateForm } from "../../utils";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { backendEAC } from "../../api";
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { authActions } from "../../redux-store";

const useStyle = makeStyles((theme) => ({
  signUpSec: {
    padding: "70px 0",
    width: "100%",
    height: "200vh",
    background: `linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)), url('/images/bgWelcome.png') 100% 0%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  Cardroot: {
    // marginTop: "20px",
    maxWidth: 740,
    maxHeight: 1100,
    textAlign: "center",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 740,
      maxHeight: 1100,
    },
  },
  form: {
    padding: "10px",
    margin: "0",
  },
  btns: {
    display: "flex",
    flexFlow: "row wrap",
    "& button": {
      margin: "8px 0",
      borderRadius: "0",
      padding: "0 18px",
    },
  },
}));

const SignUp = ({ history }) => {
  const classes = useStyle();
  const [formData, setFormData] = useState(signUpFormConfig);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentinfo, setPaymentInfo] = useState("paypal_account");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registerErrors, setRegisterErrors] = useState(null);
  const formValueChangeHandler = (event, fieldIdentifier) => {
    const updatedForm = {
      ...formData,
      [fieldIdentifier]: {
        ...formData[fieldIdentifier],
        elementConfig: { ...formData[fieldIdentifier].elementConfig },
        value: event.target.value,
        valid: validateField(
          event.target.value,
          formData[fieldIdentifier].validatingRules
        ),
      },
    };
    setFormData(updatedForm);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (formData.password.value !== formData.confirmPassword.value) {
      const updatedForm = {
        ...formData,
        confirmPassword: {
          ...formData.confirmPassword,
          elementConfig: { ...formData.confirmPassword.elementConfig },
          valid: false,
        },
      };
      setFormData(updatedForm);
      return;
    }
    let valid = false;
    if (paymentinfo == "paypal_account") {
      valid = validateField(paymentData.paypal_account, {
        required: true,
        isEmail: true,
      });
      if (valid == false) {
        setError("This value is required and must be a valid email!");
        return;
      } else {
        setError(null);
      }
    } else {
      valid = validateField(paymentData.bank_routing, {
        required: true,
        number: true,
      });
      if (valid == false) {
        setError("This value is required and must be a 9 digit number");
        return;
      } else {
        setError(null);
      }
    }

    if (valid == true) {
      const postData = {
        first_name: formData.first_name.value,
        last_name: formData.last_name.value,
        email: formData.email.value,
        password: formData.password.value,
        city: formData.city.value,
        country: formData.country.value,
        phone: formData.phone.value,
        company_name: formData.company_name.value,
        company_address: `${formData.name.value}, ${formData.city.value}, ${formData.state.value} - ${formData.zip.value}, ${formData.country.value}`,
        website: formData.website.value,
        zip: formData.zip.value,
        state: formData.state.value,
        ...paymentData,
      };
      try {
        setLoading(true);
        const {
          data: { data },
        } = await backendEAC.post(
          `/user/create/${formData.As.value}`,
          postData
        );
        if (data) navigateUrl(`${process.env.REACT_APP_EAC_URL}/login`);
        history.push("/");
      } catch (err) {
        toast.error(err.response ? err.response.data.message : err.message);
        setRegisterErrors(
          err.response ? err.response.data.message : err.message
        );
      } finally {
        setLoading(false);
      }
    }
  };
  const handleChangePaymentData = (value) => {
    setPaymentData({ [paymentinfo]: value });
  };

  return (
    <>
      <Helmet>
        <title>Signup - C5M</title>
      </Helmet>
      <section className={classes.signUpSec}>
        <Card className={classes.Cardroot}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Sign Up To C5M
            </Typography>
            <br />
            <Typography variant="body2" component="p">
              Already Signed Up? <Link to="/login">Login!</Link>
            </Typography>
            <hr />
            {loading && <CircularProgress />}
            {registerErrors && <Alert variant="error">{registerErrors}</Alert>}
            <form className={classes.form}>
              <Grid container lg={12} spacing={2}>
                {Object.keys(formData).map((el) => (
                  <Grid item lg={6} xs={6}>
                    <Formfield
                      key={el}
                      label={formData[el].label}
                      elementType={formData[el].elementType}
                      value={formData[el].value}
                      elementConfig={formData[el].elementConfig}
                      options={formData[el].options}
                      required={formData[el].validatingRules.required}
                      invalid={!formData[el].valid}
                      invalidMessage={formData[el].validityMessage}
                      valueChanged={(event) =>
                        formValueChangeHandler(event, el)
                      }
                    />
                  </Grid>
                ))}

                <Grid container md={12} lg={12}>
                  <Grid item md={6} lg={6}>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginBottom: "5px",
                      }}
                      htmlFor="broute"
                    >
                      Receive Payments Via
                    </label>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        marginBottom: "5px",
                      }}
                    >
                      <input
                        type="radio"
                        value="paypal_account"
                        checked={paymentinfo === "paypal_account"}
                        onChange={(e) => {
                          setPaymentInfo(e.target.value);
                          const data = { [e.target.value]: "" };
                          setError(null);
                          setPaymentData(data);
                        }}
                      />
                      <p>Paypal Account</p>
                    </div>

                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <input
                        type="radio"
                        value="bank_routing"
                        checked={paymentinfo === "bank_routing"}
                        onChange={(e) => {
                          setPaymentInfo(e.target.value);
                          const data = { [e.target.value]: 0 };
                          setError(null);
                          setPaymentData(data);
                        }}
                      />
                      <p>Bank's Routing Number</p>
                    </div>
                  </Grid>

                  <Grid item md={6} lg={6}>
                    {paymentinfo == "paypal_account" ? (
                      <TextField
                        variant="filled"
                        name="paypal_account"
                        type="email"
                        label="Paypal Account"
                        fullWidth
                        value={paymentData?.paypal_account}
                        inputProps={{
                          name: "paypal_account",
                          type: "text",
                          placeholder: "Paypal Email",
                        }}
                        error={error ? true : false}
                        helperText={error ? error : ""}
                        onChange={(e) => {
                          handleChangePaymentData(e.target.value);
                        }}
                      />
                    ) : (
                      <TextField
                        variant="filled"
                        name="bank_routing"
                        type="number"
                        label="Bank's Routing Number"
                        fullWidth
                        value={paymentData?.bank_routing}
                        inputProps={{
                          name: "paypal_account",
                          type: "bank_routing",
                          placeholder: "Bank's Routing Number",
                        }}
                        error={error ? true : false}
                        helperText={error ? error : ""}
                        onChange={(e) =>
                          handleChangePaymentData(e.target.value)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  disabled={!validateForm(formData) || !paymentData}
                  onClick={(e) => formSubmitHandler(e)}
                >
                  Sign Up
                </Button>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default SignUp;
