import {
  Card,
  CardContent,
  Typography,
  Button,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import Formfield from "../../components/Formfeild/Formfeild";
import Alert from "../../components/Alert/Alert";
import { loginFormConfig } from "./AuthFormConfig";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// import { authActions } from "../../redux-store";
// import Helmet from "react-helmet";

const useStyle = makeStyles((theme) => ({
  loginSec: {
    padding: "70px 0",
    width: "100%",
    height: "80vh",
    background: `linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)), url('images/bgWelcome.png') 100% 50% no-repeat`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  Cardroot: {
    maxWidth: 315,
    textAlign: "center",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      maxWidth: 280,
    },
  },

  form: {
    padding: "10px",
    margin: "0",
  },
  btn: {
    width: "100%",
    // height:'100%'
    marginBottom: "20px",
  },
}));

const Login = ({ history }) => {
  const classes = useStyle();

  return (
    <>
      <Helmet>
        <title>Signup - C5M</title>
      </Helmet>
      <section className={classes.loginSec}>
        <Card className={classes.Cardroot}>
          <CardContent>
            <Typography variant="h6" component="h2">
              Sign up with C5M
            </Typography>
            <br />

            <Button
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              <Link to="/sign-up/brand">Sign up as Brand/Retailer</Link>
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.btn}
            >
              <Link to="/sign-up/author">Sign up as Author</Link>
            </Button>

            <hr />
            {/* {loading && <CircularProgress />}
            {loginErrors && <Alert variant="error">{loginErrors}</Alert>} */}
            {/* <form onSubmit={formSubmitHandler} className={classes.form}>
              {Object.keys(formData).map((el) => (
                <Formfield
                  key={el}
                  label={formData[el].label}
                  elementType={formData[el].elementType}
                  value={formData[el].value}
                  elementConfig={formData[el].elementConfig}
                  options={formData[el].options}
                  required={formData[el].validatingRules.required}
                  invalid={!formData[el].valid}
                  invalidMessage={formData[el].validityMessage}
                  valueChanged={(event) => formValueChangeHandler(event, el)}
                />
              ))}
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                fullWidth
              >
                Login
              </Button>
            </form> */}

            {/* <Typography variant="body2" component="p">
              Forgot your password?
              <Link to="/forget-password">Click here</Link>
            </Typography> */}
          </CardContent>
        </Card>
      </section>
    </>
  );
};

export default Login;
